import { EditOutlined } from '@ant-design/icons';
import { Space, theme, Typography } from 'antd';
import { ReactNode, RefObject } from 'react';
import styled from '@emotion/styled';

const { Text } = Typography;

interface StyledButtonProps {
  borderRadius: number;
  colorBgContainer: string;
  colorBorder: string;
  colorPrimary: string;
  onClick: () => void;
}

const StyledButton = styled.button<StyledButtonProps>`
  all: unset;
  display: flex;
  justify-content: space-between;
  align-items: center;
  min-height: 32px;
  padding: 8px 12px;
  background-color: ${(props) => props.colorBgContainer};
  border-radius: ${(props) => props.borderRadius}px;
  border: 1px solid ${(props) => props.colorBorder};
  cursor: pointer;
  text-align: inherit;

  &:focus {
    border-color: ${(props) => props.colorPrimary};
  }
`;

interface SearchSummaryProps {
  title: string;
  subtitle: string;
  handleAction: () => void;
  onBoardingRef?: RefObject<HTMLButtonElement>;
}

export default function SearchSummary({ title, subtitle, handleAction, onBoardingRef }: Readonly<SearchSummaryProps>): ReactNode {
  const {
    token: { borderRadius, colorBgContainer, colorBorder, colorPrimary },
  } = theme.useToken();

  return (
    <StyledButton
      onClick={handleAction}
      borderRadius={borderRadius}
      colorBgContainer={colorBgContainer}
      colorBorder={colorBorder}
      colorPrimary={colorPrimary}
      ref={onBoardingRef}
    >
      <Space style={{ flexWrap: 'wrap', fontWeight: 600 }}>
        <Text>{title} : </Text>
        <Text type="secondary" style={{ textWrap: 'wrap' }}>
          {subtitle}
        </Text>
      </Space>
      <EditOutlined style={{ color: colorPrimary }} />
    </StyledButton>
  );
}
